import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import { EffectCreative } from "swiper/modules";
import ArrowRightUpLine from "@/public/icons/arrow-right.svg";
import { Button } from "antd";
import { useTranslation } from "next-i18next";
import Link from "next/link";

const ContentWrapper = ({ description, author, logo, role, avatar }) => {
  return (
    <>
      <div className="w-full h-full px-11 py-10 lg:p-8 md:p-4 sm:p-2 flex flex-col justify-between border border-solid border-[#1E1E1E] bg-white">
        <div className="flex flex-col gap-11 lg:gap-6 md:gap-3 heading">
          <img
            src={logo}
            className="max-h-[60px] min-h-[40px] lg:max-h-[40px] md:max-h-[30px] object-contain object-center mr-auto"
            alt="CyStack"
          />
          <p className="text-[27px] lg:text-[20px] lg:leading-7 md:text-[16px] sm:text-[14px] md:leading-5 font-cystack font-normal">
            {description}
          </p>
        </div>
        <div className="flex items-center gap-6 sm:gap-3">
          <img
            src={avatar}
            className="w-16 h-16 rounded-full lg:w-12 lg:h-12 md:w-8 md:h-8"
            alt=""
          />
          <div className="font-semibold">
            <p className="mb-[6px] text-dark-title text-[27px] lg:text-[21px] lg:leading-7 md:text-[18px] md:leading-5 sm:text-[14px] sm:leading-4 font-bold font-cystack leading-[36px]">
              <strong>{author}</strong>
            </p>
            <p className="font-normal uppercase text-grey md:text-[12px]">
              {role}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Testimonial() {
  const { t } = useTranslation(["home"]);
  const [activeSlide, setActiveSlide] = useState(2);

  const handleChangeSlide = (slide) => {
    setActiveSlide(slide.activeIndex);
  };

  const swiperRef = useRef(null);

  const handleSlideTo = (array) => {
    let closest = array[0];
    for (let i = 1; i < array.length; i++) {
      if (Math.abs(array[i] - activeSlide) < Math.abs(closest - activeSlide)) {
        closest = array[i];
      }
    }
    swiperRef.current.swiper.slideTo(closest);
  };
  return (
    <>
      <section className="py-20 md:py-10 home-testimonial">
        <div className="container">
          <h2 className="max-w-[680px] mx-auto text-dark-title mb-12 text-center text-display-lg lg:text-display-md">
            {t("testimonial.title")}
          </h2>
          <Link href="/customers">
            <Button className="flex items-center h-auto gap-1 py-3 mx-auto text-xl text-white rounded-none md:px-6 md:text-lg px-9 bg-neutral-dark hover:border-neutral-dark">
              <span>{t("testimonial.read_case_study")}</span>
              <ArrowRightUpLine className="text-white " />
            </Button>
          </Link>
          <div className="mt-16">
            <Swiper
              ref={swiperRef}
              onSlideChange={handleChangeSlide}
              slidesPerView={"auto"}
              centeredSlides={true}
              grabCursor={true}
              effect={"creative"}
              loop={true}
              slideToClickedSlide={true}
              creativeEffect={{
                prev: {
                  translate: ["-12%", 0, 0],
                  scale: 0.85,
                },
                next: {
                  translate: ["12%", 0, 0],
                  scale: 0.85,
                },
              }}
              modules={[EffectCreative]}
            >
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/vntrip-active.svg"}
                  author="Nguyen Hong Thai"
                  role="CTO VNTRIP"
                  avatar={"/images/home/testimonial/vntrip-cto.svg"}
                  description={t("testimonial.item_2.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/cellframe.svg"}
                  author="Dmitriy Gerasimov"
                  role="FOUNDER/CEO CELLFRAME"
                  avatar={"/images/home/testimonial/cellframe-cto.svg"}
                  description={t("testimonial.item_1.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/fire-apps-active.svg"}
                  author="Truong Hoang Dung"
                  role="CS team leader fireapps"
                  avatar={"/images/home/testimonial/fire-apps-cto.svg"}
                  description={t("testimonial.item_3.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/getfly-active.svg"}
                  author="Nguyen Hoang Huy"
                  role="CTO Getfly"
                  avatar={"/images/home/testimonial/getfly-cto.svg"}
                  description={t("testimonial.item_4.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/vntrip-active.svg"}
                  author="Nguyen Hong Thai"
                  role="CTO VNTRIP"
                  avatar={"/images/home/testimonial/vntrip-cto.svg"}
                  description={t("testimonial.item_2.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/cellframe.svg"}
                  author="Dmitriy Gerasimov"
                  role="FOUNDER/CEO CELLFRAME"
                  avatar={"/images/home/testimonial/cellframe-cto.svg"}
                  description={t("testimonial.item_1.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/fire-apps-active.svg"}
                  author="Truong Hoang Dung"
                  role="CS team leader fireapps"
                  avatar={"/images/home/testimonial/fire-apps-cto.svg"}
                  description={t("testimonial.item_3.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/getfly-active.svg"}
                  author="Nguyen Hoang Huy"
                  role="CTO Getfly"
                  avatar={"/images/home/testimonial/getfly-cto.svg"}
                  description={t("testimonial.item_4.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/vntrip-active.svg"}
                  author="Nguyen Hong Thai"
                  role="CTO VNTRIP"
                  avatar={"/images/home/testimonial/vntrip-cto.svg"}
                  description={t("testimonial.item_2.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/cellframe.svg"}
                  author="Dmitriy Gerasimov"
                  role="FOUNDER/CEO CELLFRAME"
                  avatar={"/images/home/testimonial/cellframe-cto.svg"}
                  description={t("testimonial.item_1.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/fire-apps-active.svg"}
                  author="Truong Hoang Dung"
                  role="CS team leader fireapps"
                  avatar={"/images/home/testimonial/fire-apps-cto.svg"}
                  description={t("testimonial.item_3.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/getfly-active.svg"}
                  author="Nguyen Hoang Huy"
                  role="CTO Getfly"
                  avatar={"/images/home/testimonial/getfly-cto.svg"}
                  description={t("testimonial.item_4.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/vntrip-active.svg"}
                  author="Nguyen Hong Thai"
                  role="CTO VNTRIP"
                  avatar={"/images/home/testimonial/vntrip-cto.svg"}
                  description={t("testimonial.item_2.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/cellframe.svg"}
                  author="Dmitriy Gerasimov"
                  role="FOUNDER/CEO CELLFRAME"
                  avatar={"/images/home/testimonial/cellframe-cto.svg"}
                  description={t("testimonial.item_1.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/fire-apps-active.svg"}
                  author="Truong Hoang Dung"
                  role="CS team leader fireapps"
                  avatar={"/images/home/testimonial/fire-apps-cto.svg"}
                  description={t("testimonial.item_3.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/getfly-active.svg"}
                  author="Nguyen Hoang Huy"
                  role="CTO Getfly"
                  avatar={"/images/home/testimonial/getfly-cto.svg"}
                  description={t("testimonial.item_4.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/vntrip-active.svg"}
                  author="Nguyen Hong Thai"
                  role="CTO VNTRIP"
                  avatar={"/images/home/testimonial/vntrip-cto.svg"}
                  description={t("testimonial.item_2.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/cellframe.svg"}
                  author="Dmitriy Gerasimov"
                  role="FOUNDER/CEO CELLFRAME"
                  avatar={"/images/home/testimonial/cellframe-cto.svg"}
                  description={t("testimonial.item_1.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/fire-apps-active.svg"}
                  author="Truong Hoang Dung"
                  role="CS team leader fireapps"
                  avatar={"/images/home/testimonial/fire-apps-cto.svg"}
                  description={t("testimonial.item_3.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/getfly-active.svg"}
                  author="Nguyen Hoang Huy"
                  role="CTO Getfly"
                  avatar={"/images/home/testimonial/getfly-cto.svg"}
                  description={t("testimonial.item_4.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/vntrip-active.svg"}
                  author="Nguyen Hong Thai"
                  role="CTO VNTRIP"
                  avatar={"/images/home/testimonial/vntrip-cto.svg"}
                  description={t("testimonial.item_2.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/cellframe.svg"}
                  author="Dmitriy Gerasimov"
                  role="FOUNDER/CEO CELLFRAME"
                  avatar={"/images/home/testimonial/cellframe-cto.svg"}
                  description={t("testimonial.item_1.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/fire-apps-active.svg"}
                  author="Truong Hoang Dung"
                  role="CS team leader fireapps"
                  avatar={"/images/home/testimonial/fire-apps-cto.svg"}
                  description={t("testimonial.item_3.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/getfly-active.svg"}
                  author="Nguyen Hoang Huy"
                  role="CTO Getfly"
                  avatar={"/images/home/testimonial/getfly-cto.svg"}
                  description={t("testimonial.item_4.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/vntrip-active.svg"}
                  author="Nguyen Hong Thai"
                  role="CTO VNTRIP"
                  avatar={"/images/home/testimonial/vntrip-cto.svg"}
                  description={t("testimonial.item_2.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/cellframe.svg"}
                  author="Dmitriy Gerasimov"
                  role="FOUNDER/CEO CELLFRAME"
                  avatar={"/images/home/testimonial/cellframe-cto.svg"}
                  description={t("testimonial.item_1.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/fire-apps-active.svg"}
                  author="Truong Hoang Dung"
                  role="CS team leader fireapps"
                  avatar={"/images/home/testimonial/fire-apps-cto.svg"}
                  description={t("testimonial.item_3.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/getfly-active.svg"}
                  author="Nguyen Hoang Huy"
                  role="CTO Getfly"
                  avatar={"/images/home/testimonial/getfly-cto.svg"}
                  description={t("testimonial.item_4.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/vntrip-active.svg"}
                  author="Nguyen Hong Thai"
                  role="CTO VNTRIP"
                  avatar={"/images/home/testimonial/vntrip-cto.svg"}
                  description={t("testimonial.item_2.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/cellframe.svg"}
                  author="Dmitriy Gerasimov"
                  role="FOUNDER/CEO CELLFRAME"
                  avatar={"/images/home/testimonial/cellframe-cto.svg"}
                  description={t("testimonial.item_1.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/fire-apps-active.svg"}
                  author="Truong Hoang Dung"
                  role="CS team leader fireapps"
                  avatar={"/images/home/testimonial/fire-apps-cto.svg"}
                  description={t("testimonial.item_3.desc")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentWrapper
                  logo={"/images/home/testimonial/getfly-active.svg"}
                  author="Nguyen Hoang Huy"
                  role="CTO Getfly"
                  avatar={"/images/home/testimonial/getfly-cto.svg"}
                  description={t("testimonial.item_4.desc")}
                />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="flex items-center justify-between mt-6 lg:gap-0 max-w-[1010px] xl:max-w-[900px] lg:max-w-full mx-auto flex-wrap">
            <div className="relative flex items-center justify-center w-1/4 px-3 py-6 md:w-1/2">
              {[2, 6, 10, 14, 18, 22, 26, 30].includes(activeSlide) ? (
                <>
                  <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-primary-500"></div>
                  <img
                    src="/images/home/testimonial/vntrip.svg"
                    className="h-[32px] max-w-full"
                    alt="CyStack"
                  />
                </>
              ) : (
                <img
                  onClick={() => handleSlideTo([2, 6, 10, 14, 18, 22, 26, 30])}
                  src="/images/home/testimonial/vntrip-logo.svg"
                  className="h-[32px] max-w-full cursor-pointer"
                  alt="CyStack"
                />
              )}
            </div>
            <div className="relative flex items-center justify-center w-1/4 px-3 py-6 md:w-1/2">
              {[3, 7, 11, 15, 19, 23, 27, 31].includes(activeSlide) ? (
                <>
                  <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-primary-500"></div>
                  <img
                    src="/images/home/testimonial/cellframe.svg"
                    className="h-[32px] max-w-full"
                    alt="CyStack"
                  />
                </>
              ) : (
                <img
                  onClick={() => handleSlideTo([3, 7, 11, 15, 19, 23, 27, 31])}
                  src="/images/home/testimonial/cellframe-logo.svg"
                  className="h-[32px] max-w-full cursor-pointer"
                  alt="CyStack"
                />
              )}
            </div>
            <div className="relative flex items-center justify-center w-1/4 px-3 py-6 md:w-1/2">
              {[0, 4, 8, 12, 16, 20, 24, 28].includes(activeSlide) ? (
                <>
                  <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-primary-500"></div>
                  <img
                    src="/images/home/testimonial/fire-apps.svg"
                    className="h-[32px] max-w-full"
                    alt="CyStack"
                  />
                </>
              ) : (
                <img
                  onClick={() => handleSlideTo([0, 4, 8, 12, 16, 20, 24, 28])}
                  src="/images/home/testimonial/fire-apps-logo.svg"
                  className="h-[32px] max-w-full cursor-pointer"
                  alt="CyStack"
                />
              )}
            </div>
            <div className="relative flex items-center justify-center w-1/4 px-3 py-6 md:w-1/2">
              {[1, 5, 9, 13, 17, 21, 25, 29].includes(activeSlide) ? (
                <>
                  <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-primary-500"></div>
                  <img
                    src="/images/home/testimonial/getfly.svg"
                    className="h-[32px] max-w-full"
                    alt="CyStack"
                  />
                </>
              ) : (
                <img
                  onClick={() => handleSlideTo([1, 5, 9, 13, 17, 21, 25, 29])}
                  src="/images/home/testimonial/getfly-logo.svg"
                  className="h-[32px] max-w-full cursor-pointer"
                  alt="CyStack"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
